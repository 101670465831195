import { useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';


export const useIsSubscriptionActive = () => {
  const { user } = useAuth();

  return useMemo(() => {
    const subscriptionExpirationDate = user?.subscriptionExpirationDate;
    if (user && subscriptionExpirationDate) {
      const now = new Date().getTime();

      return now < subscriptionExpirationDate;
    }

    return false;
  }, [user]);
};
