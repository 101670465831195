import { QuestionCircleOutlined } from '@ant-design/icons';
import { ReactComponent as DashboardIconOutline } from '../assets/icons/outlined/dashboard-outline.svg';
import { ReactComponent as ProjectIconOutline } from '../assets/icons/outlined/project-outline.svg';
import { ReactComponent as NewProjectIconOutline } from '../assets/icons/outlined/new-project-outline.svg';
import {
  ReactComponent as DataResourcesIconOutline,
} from '../assets/icons/outlined/data-resources-outline.svg';
import { ReactComponent as SettingsIconOutline } from '../assets/icons/outlined/settings-outline.svg';
import { ReactComponent as PersonIcon } from '../assets/icons/person.svg';


export const adminPanelsSideMenuItemsConfig = [
  {
    key: '1',
    label: 'Companies',
    icon: <DashboardIconOutline />,
    to: '/admin/companies',
  },
  {
    key: '2',
    label: 'Users',
    icon: <PersonIcon />,
    to: '/admin/users',
  },
  {
    key: '3',
    label: 'Provisioned Surveys',
    icon: <ProjectIconOutline />,
    to: '/admin/projects',
  },
  {
    key: '4',
    label: 'Live Surveys',
    icon: <ProjectIconOutline />,
    to: '/admin/projects/live',
  },
  {
    key: '5',
    label: 'Partner Surveys',
    icon: <ProjectIconOutline />,
    to: '/admin/projects/partner',
  },
  {
    key: '6',
    label: 'Customer Surveys',
    icon: <ProjectIconOutline />,
    to: '/admin/projects/customer',
  },
  {
    key: '7',
    label: 'Create Survey',
    icon: <NewProjectIconOutline />,
    to: '/admin/project/add',
  },
  {
    key: '8',
    label: 'Data Resources',
    icon: <DataResourcesIconOutline />,
    to: '/admin/data-resources',
  },
  {
    key: '9',
    label: 'Settings',
    icon: <SettingsIconOutline />,
    to: '/settings',
  },
];

export const sideMenuItemsConfig = [
  {
    key: '1',
    label: 'Dashboard',
    // TODO: Selected tab should have a filled icon
    icon: <DashboardIconOutline />,
    to: '/dashboard',
  },
  {
    key: '2',
    label: 'Surveys',
    icon: <ProjectIconOutline />,
    to: '/projects',
  },
  {
    key: '3',
    label: 'Add New Survey',
    icon: <NewProjectIconOutline />,
    to: '/add-project',
  },
  {
    key: '4',
    label: 'Data Resources',
    icon: <DataResourcesIconOutline />,
    to: '/data-resources',
  },
  {
    key: '5',
    label: 'Monitor Competitors',
    icon: <DataResourcesIconOutline />,
    to: '/competitors-pricing',
  },
  {
    key: '6',
    label: 'Help Resources',
    icon: <QuestionCircleOutlined style={{ fontSize: '20px' }} />,
    to: '/help-resources',
  },
  {
    key: '7',
    label: 'Settings',
    icon: <SettingsIconOutline />,
    to: '/settings',
  },
];
