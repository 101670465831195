import styled from 'styled-components';
import { Badge } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from '../../atoms/Button';


export const StyledBadge = styled(Badge)`
  margin: 0 42px;
  cursor: pointer;
`;

export const StyledNotification = styled.div`
  
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  
`;

export const StyledNotificationWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  p {
    padding: 6px 12px 6px 0;
    margin: 0;
  }
`;

export const StyledNotificationItemBottomBlock = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const StyledArrowRightOutlined = styled(ArrowRightOutlined)`
    cursor: pointer;
`;

export const StyledMarkAsRead = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.greyscale.grey40};
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  
  &:hover {
    color: ${({ theme }) => theme.color.greyscale.grey80};
  }
`;

export const StyledReadMoreButton = styled(Button)`
  margin: 0 auto;
`;

export const StyledDate = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.greyscale.grey40};
`;
