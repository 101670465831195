export const breadcrumbNameMap: Record<string, string> = {
  '/dashboard': 'Dashboard',
  '/projects': 'Surveys',
  '/add-project': 'Add New Survey',
  '/data-resources': 'Data Resources',
  '/competitors-pricing': 'Monitor Competitors',
  '/help-resources': 'Help Resources',
  '/settings': 'Settings',
  '/settings/user-settings': 'User Settings',
  '/settings/company-info': 'Company Info',
  '/settings/account-settings': 'Account Settings',
  '/settings/billings': 'Billings',
  '/settings/add-ons': 'Add-ons',
  '/settings/users': 'All Users',
  '/settings/support': 'Support',
};
