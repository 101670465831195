import { FC } from 'react';
import { Tour, TourProps } from 'antd';
import { refParentNode } from '../../../helpers/refParentNode';
import { useTour } from '../../../contexts/TourContext';


interface OnBoardingTourProps {
  showOnBoardingTour: boolean,
  onClose: () => void;
}

export const OnBoardingTour: FC<OnBoardingTourProps> = ({ showOnBoardingTour, onClose }) => {
  const {
    EntireMarketRef,
    SegmentationRef,
    DashboardRef,
    ProjectsRef,
    AddNewProjectRef,
    DataResourcesRef,
    SettingsRef
  } = useTour();

  const steps: TourProps['steps'] = [
    {
      title: 'Welcome to PriceAgent',
      description: `PriceAgent - a pricing intelligence software! Our goal is to help you make better 
      pricing decisions for your business.`
    },
    {
      title: `Upon logging in, you will be taken to the dashboard. Here, you will see an overview of your latest 
      pricing data, including charts and tables displaying your optimal prices points.`,
      description: 'If you don’t have any surveys of your own - you will see the latest free data resource.',
      placement: 'right',
      target: () => refParentNode(DashboardRef) as HTMLElement,
    },
    {
      title: 'Here you can see all your past surveys, edit or duplicate them.',
      placement: 'right',
      target: () => refParentNode(ProjectsRef) as HTMLElement,
    },
    {
      title: 'To measure the willingness to pay for your product or service you simply press ‘add new survey’.',
      placement: 'right',
      target: () => refParentNode(AddNewProjectRef) as HTMLElement,
    },
    {
      title: `By filling out the questions in the form your research design is ready to be launched toward your 
      targeted audience.`,
      placement: 'right',
      target: () => refParentNode(AddNewProjectRef) as HTMLElement,
    },
    {
      title: `Here you see the aggregated data of the latest survey - you can see both the revenue line and 
      demand line. You can slice and dice the data based on demographics.`,
      target: () => refParentNode(EntireMarketRef) as HTMLElement,
    },
    {
      title: `When you press segmentation you will get granular insights on your data. Here you can compare which 
      attributes or aspects of your product or service that drives your customers willingness to pay. `,
      description: `For example, you can learn whether your customer would rather pay for an organic product or 
      a sleek design.`,
      target: () => refParentNode(SegmentationRef) as HTMLElement,
    },
    {
      title: `The "Data Resource" tab provides a detailed analysis of your pricing data, including trends over
      time and comparison. Over the years, Priceagent has accumulated a vast database of pricing research. Here are
      some findings on the pricing changes in industries and across demographics. Completely free of charge.`,
      placement: 'right',
      target: () => refParentNode(DataResourcesRef) as HTMLElement,
    },
    {
      title: `Finally, the "Settings" tab allows you to customize your account, such as adding team members 
      or adjusting the subscription model. If you need help or support this is where you should look.`,
      placement: 'right',
      target: () => refParentNode(SettingsRef) as HTMLElement,
    }
  ];

  return <Tour open={showOnBoardingTour} onClose={onClose} steps={steps} />;
};
