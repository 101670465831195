import type { FC, SVGProps } from 'react';
import { StyledLogo } from './Logo.styles';


interface LogoProps extends SVGProps<SVGSVGElement> {
  width?: string;
  height?: string;
}

export const Logo: FC<LogoProps> = ({
  width = '310px',
  height = '80px',
  ref,
  ...rest
}) => (
  <StyledLogo width={width} height={height} {...rest} />
);
