import type { FC } from 'react';
import { StyledErrorFallback } from './ErrorFallback.styles';


const ErrorFallback: FC = () => {
  return (
    <StyledErrorFallback
      status="500"
      title="Oooops!"
      subTitle="Something went wrong 😓"
    />
  );
};

export default ErrorFallback;
