import {
  useCallback, useEffect, useMemo, useState
} from 'react';
import {
  Drawer, Dropdown, MenuProps, notification, Space
} from 'antd';
import {
  equalTo, onChildAdded, onValue, orderByChild, query, ref, update
} from 'firebase/database';
import ReactMarkdown from 'react-markdown';
import { BellOutlined } from '@ant-design/icons';
import notificationIconWhite from '../../../assets/icons/notification-white.svg';
import notificationIcon from '../../../assets/icons/notification.svg';
import {
  StyledBadge, StyledReadMoreButton
} from './NotificationCenter.styles';
import { realTimeDatabase } from '../../../firebase';
import { useAuth } from '../../../contexts/AuthContext';
import { useDarkMode } from '../../../contexts/DarkModeContext';
import { NotificationsType } from '../../../types/notifications.type';
import { NotificationItem } from '../NotificationItem';


const NOTIFICATIONS_LIMIT = 5;

export const NotificationCenter = () => {
  const { user } = useAuth();
  const { isDarkMode } = useDarkMode();
  const [notificationAlert, notificationAlertContextHolder] = notification.useNotification();

  const [notifications, setNotifications] = useState<NotificationsType[]>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // NOTE: Get first {NOTIFICATIONS_LIMIT} notifications for notifications preview
  const limitedNotifications = useMemo(() => {
    return notifications?.slice(0, NOTIFICATIONS_LIMIT);
  }, [notifications]);

  const onMarkNotificationAsReadClick = useCallback((notificationId: string) => {
    if (!user?.firebaseId) {
      return;
    }

    const notificationsRefPath = `notifications/${user.firebaseId}`;
    const notificationsRef = ref(realTimeDatabase, notificationsRefPath);

    const updateKey = `${notificationId}/isRead`;
    const updateObject = { [updateKey]: true };

    return update(notificationsRef, updateObject)
      .catch((err) => console.log('Error while marking notification as read', err));
  }, [user?.firebaseId]);


  useEffect(() => {
    let notificationsListener;
    let onChildAddedListener;

    if (user?.firebaseId) {
      const notificationsRefPath = `notifications/${user.firebaseId}`;
      const notificationsRef = ref(realTimeDatabase, notificationsRefPath);
      const notificationsQuery = query(notificationsRef, orderByChild('isRead'), equalTo(false));

      // NOTE:
      //  onChildAdded event listener is called for each data child on initial load
      //  as a workaround we keep a variable to check if the initial data has been loaded
      //  then it will be triggered only for new notifications
      //  https://firebase.google.com/docs/database/web/lists-of-data?authuser=0#listen_for_child_events
      let isInitialNotificationsLoaded = false;

      notificationsListener = onValue(notificationsQuery, (snapshot) => {
        const data = snapshot.val();

        if (data) {
          const notificationValues = Object.values(data) as NotificationsType[];
          // Note: Notifications are returned from oldest to newest by default
          const reversedNotifications = notificationValues.reverse();
          setNotifications(reversedNotifications);
        } else {
          setNotifications([]);
        }
        isInitialNotificationsLoaded = true;
      });

      onChildAddedListener = onChildAdded(notificationsQuery, (snapshot) => {
        if (isInitialNotificationsLoaded) {
          const data = snapshot.val();

          notificationAlert.info({
            message: 'New Notification',
            description: <ReactMarkdown>{data.message}</ReactMarkdown>,
            placement: 'topRight'
          });
        }
      });
    }

    return () => {
      // NOTE: Firebase event listeners return an `unsubscribe` function
      notificationsListener?.();
      onChildAddedListener?.();
    };
  }, [notificationAlert, user?.firebaseId]);

  const notificationsData: MenuProps['items'] = useMemo(() => {
    if (!notifications?.length) {
      return [
        {
          key: 1,
          label: 'You have no notifications yet.',
          disabled: true,
        }
      ];
    }

    const readMoreBtn = {
      key: 6,
      label: (
        <StyledReadMoreButton type="link" onClick={() => setIsDrawerOpen(true)}>
          All Notifications
        </StyledReadMoreButton>
      )
    };

    const notificationItems: MenuProps['items'] = limitedNotifications?.map((notificationItem) => {
      const onMarkAsReadClick = (e) => {
        e.stopPropagation();
        onMarkNotificationAsReadClick(notificationItem.id);
      };

      return {
        key: notificationItem.id,
        label: <NotificationItem
          notification={notificationItem}
          onMarkAsReadClick={onMarkAsReadClick}
          onNotificationLinkClick={onMarkNotificationAsReadClick}
        />,
        icon: <BellOutlined />
      };
    });

    if (notificationItems && notifications.length > NOTIFICATIONS_LIMIT) {
      return [...notificationItems, readMoreBtn];
    }

    return notificationItems;
  }, [limitedNotifications, notifications?.length, onMarkNotificationAsReadClick]);

  const renderNotificationsInDrawer = useMemo(() => {
    if (!notifications?.length) return 'You have no notifications yet.';

    return notifications?.map((notificationItem) => {
      const onMarkAsReadClick = (e) => {
        e.stopPropagation();
        onMarkNotificationAsReadClick(notificationItem.id);
      };

      return (
        <NotificationItem
          key={notificationItem.id}
          notification={notificationItem}
          onMarkAsReadClick={onMarkAsReadClick}
          onNotificationLinkClick={onMarkNotificationAsReadClick}
        />
      );
    });
  }, [notifications, onMarkNotificationAsReadClick]);

  return (
    <>
      <Dropdown
        menu={{ items: notificationsData }}
        trigger={['click']}
        placement="bottomRight"
        overlayClassName="notifications-dropdown"
      >
        <Space>
          <StyledBadge count={notifications?.length ?? null}>
            <img src={isDarkMode ? notificationIconWhite : notificationIcon} alt="notification icon" />
          </StyledBadge>
        </Space>
      </Dropdown>

      {notificationAlertContextHolder}

      <Drawer title="All Notifications" placement="right" onClose={() => setIsDrawerOpen(false)} open={isDrawerOpen}>
        {renderNotificationsInDrawer}
      </Drawer>
    </>
  );
};

