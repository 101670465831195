import { MessageInstance } from 'antd/lib/message/interface';


export const displayErrorAlert = (
  messageApi: MessageInstance,
  error: Error | unknown,
  duration?: number
) => {
  if (!error) {
    return;
  }
  if (typeof error === 'object' && 'message' in error && typeof error.message === 'string') {
    messageApi.error(error.message, duration);
    return;
  }
  // Nested error object => { error: { message: ''} }
  if (error && typeof error === 'object'
    && 'error' in error && error.error && typeof error.error === 'object'
    && 'message' in error.error && typeof error.error.message === 'string') {
    messageApi.error(error.error.message, duration);
    return;
  }
  if (error instanceof Error) {
    messageApi.error(error.message, duration);
    return;
  }
  console.debug('error', error);

  return;
};
