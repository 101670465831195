import { lazy } from 'react';


export const lazyWithRetry = (importComponent) => lazy(async () => {
  const forceRefreshTokenName = 'isPageForceRefreshed';


  const isPageRefreshedRaw = window.localStorage.getItem(forceRefreshTokenName) || 'false';
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(isPageRefreshedRaw);

  try {
    const component = await importComponent();

    window.localStorage.setItem(forceRefreshTokenName, 'false');

    return component;
  } catch (error) {
    if (!pageHasAlreadyBeenForceRefreshed) {
      // Assume that the user has an outdated version of the index.html
      window.localStorage.setItem(forceRefreshTokenName, 'true');

      return window.location.reload();
    }

    // The page has already been reloaded
    // Assuming that user is already using the latest version of the application.
    // Let's let the application crash and raise the error.
    throw error;
  }
});
