import {
  createContext, FC, ReactNode, useContext, useMemo,
} from 'react';
import { message } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import { displayErrorAlert } from '../helpers/displayErrorAlert';


interface AlertContextType extends MessageInstance {
  displayErrorAlert: (error: Error | unknown, duration?: number) => void;
}

const AlertContext = createContext<AlertContextType | null>(null);

export const useAlert = () => useContext(AlertContext);

export const AlertProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [messageApi, messageContextHolder] = message.useMessage();

  const value = useMemo(() => ({
    ...messageApi,
    displayErrorAlert: displayErrorAlert.bind(null, messageApi),
  }), [messageApi]);

  return (
    <AlertContext.Provider value={value}>
      {messageContextHolder}
      {children}
    </AlertContext.Provider>
  );
};
