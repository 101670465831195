import styled from 'styled-components';
import { LogoutOutlined } from '@ant-design/icons';
import defaultAvatar from '../../../assets/img/default-avatar.png';
import { tabletSize } from '../../../constants/mediaQuery';


export const StyledHeader = styled.header`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 114px;
    padding: 20px;
    background: ${({ theme }) => theme.currentModeConfig.contentBackground};
    gap: 16px;
    
    * {
      font-family: Inter, sans-serif;
    }
  }
`;

export const StyledLogoutOutlined = styled(LogoutOutlined)`
  &&& {
    font-size: 18px;
    margin: 0 12px 0 4px;
  }
`;

export const StyledRightContent = styled.div<{ isDashboardPage: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @media (max-width: ${tabletSize}px) {
    flex-wrap: wrap;
    flex-direction: ${({ isDashboardPage }) => (isDashboardPage ? 'column-reverse' : 'row')};
    justify-content: end;

    button {
      width: ${({ isDashboardPage }) => (isDashboardPage ? '100%' : '')};
    }
  }
`;

export const StyledHeaderTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.currentModeConfig.titleVariant1};
  margin-bottom: 4px;

  .welcome-block {
    font-weight: 400;

    b {
      display: inline-block;
    }
  }
`;

export const StyledPageDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.greyscale.grey60};
`;

export const StyledProfileCard = styled.div`
  position: relative;
  display: flex;
  
  align-items: center;
  cursor: pointer;
`;

export const StyledSearchBlock = styled.div`
  margin-left: 32px;
  font-size: 20px;
  cursor: pointer;
`;

export const StyledUserAvatar = styled.div<{ avatarUrl?:string }>`
  background-image: ${({ avatarUrl }) => `url(${avatarUrl || defaultAvatar})`};
  background-size: cover;
  background-position: center;
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 4px;
`;

export const StyledUserName = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.currentModeConfig.titleVariant2};
`;

export const StyledUserEmail = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.color.greyscale.grey40};
`;

export const StyledWelcomeBlock = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

