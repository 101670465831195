import { useEffect, useMemo } from 'react';
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Spinner } from '../atoms/Spinner';
import { useAlert } from '../../contexts/AlertContext';
import { AdminRoute } from './AdminRoute';
import { getSelf } from '../../api/user';


interface PrivateRouteProps {
  children: ReactNode;
  isAdminRoute?: boolean;
  redirectPath?: string;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ children, isAdminRoute, redirectPath = '/login' }) => {
  const {
    firebaseUser,
    isUserLoading,
    setIsUserLoading,
    user,
    logout,
    setUser
  } = useAuth();
  const alert = useAlert();

  useEffect(() => {
    if (firebaseUser && !isUserLoading && !user) {
      const fetchSelf = async () => {
        try {
          setIsUserLoading(true);
          const self = await getSelf();
          setUser(self);
        } catch (err) {
          console.debug('err', err);
        } finally {
          setIsUserLoading(false);
        }
      };

      fetchSelf();
    }
  }, [user, isUserLoading, setUser, firebaseUser, setIsUserLoading]);

  // TODO: if !user.companyId => navigate to /sign-up/company

  const shouldRedirect = useMemo(
    () => (!isUserLoading && !firebaseUser) || (!isUserLoading && firebaseUser && !user),
    [
      isUserLoading,
      firebaseUser,
      user,
    ]
  );

  if (user && !user.isAdmin) {
    const { subscriptionExpirationDate } = user;

    if (!subscriptionExpirationDate) {
      return <Navigate to="/subscription-expired" />;
    }
    const now = new Date().getTime();

    const isSubscriptionExpired = now > subscriptionExpirationDate;
    if (isSubscriptionExpired) {
      return <Navigate to="/subscription-expired" />;
    }
  }

  if (isUserLoading) {
    return <Spinner tip="Loading..." withOverlay />;
    // return <Spinner tip="Loading..." size="large" withOverlay />;
  }

  if (shouldRedirect) {
    if (firebaseUser && !user) {
      alert?.error('User not found, try to login again');

      logout();
    }
    return <Navigate to={redirectPath} />;
  }

  if (isAdminRoute) {
    if (!user?.isAdmin) {
      return <Navigate to={redirectPath} />;
    }
  }

  if (user?.isAdmin) {
    return <AdminRoute>{children}</AdminRoute>;
  }

  return <>{children}</>;
};
