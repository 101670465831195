import { useMemo } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { ConfigProvider, theme as AntDTheme } from 'antd';
import { ThemeProvider } from 'styled-components';
import Routes from './Routes';
import { AuthProvider } from './contexts/AuthContext';
import './App.css';
import {
  antDesignDarkTheme, antDesignTheme, darkModeConfig, GlobalStyles, lightModeConfig, theme
} from './theme';
import { setupAxios } from './config/axios';
import { useDarkMode } from './contexts/DarkModeContext';
import { AlertProvider } from './contexts/AlertContext';


setupAxios();

function App() {
  const { isDarkMode } = useDarkMode();

  const themeConfig = useMemo(() => {
    const currentModeConfig = isDarkMode ? darkModeConfig : lightModeConfig;

    return { ...theme, currentModeConfig };
  }, [isDarkMode]);

  const antDesignThemeConfig = useMemo(() => {
    return isDarkMode ? antDesignDarkTheme : antDesignTheme;
  }, [isDarkMode]);

  const handleAlgorithm = useMemo(() => {
    if (isDarkMode) {
      return { algorithm: AntDTheme.darkAlgorithm };
    }

    return { algorithm: AntDTheme.defaultAlgorithm, ...antDesignThemeConfig };
  }, [isDarkMode, antDesignThemeConfig]);

  const routesFromElements = createRoutesFromElements(Routes());
  const router = createBrowserRouter(routesFromElements);

  return (
    <ThemeProvider theme={themeConfig}>
      <ConfigProvider theme={handleAlgorithm}>
        <AlertProvider>
          <AuthProvider>
            <GlobalStyles />
            <RouterProvider router={router} />
          </AuthProvider>
        </AlertProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;
