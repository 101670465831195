import { FC } from 'react';
import { Breadcrumb } from 'antd';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';


interface BreadcrumbsProps {
  breadcrumbItems: BreadcrumbItemType[];
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbItems }) => <Breadcrumb items={breadcrumbItems} />;
