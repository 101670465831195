import { useMemo } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuProps } from 'antd';
import { StyledSideMenu } from './SideMenu.styles';
import {
  sideMenuItemsConfig,
  adminPanelsSideMenuItemsConfig,
} from '../../../constants/sideMenuItemsConfig';
import { useAuth } from '../../../contexts/AuthContext';
import { useTour } from '../../../contexts/TourContext';


type SideMenuProps = MenuProps;

export const SideMenu: FC<SideMenuProps> = ({ theme, ...props }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const tourRefs = useTour();

  const filteredSideMenuItems = useMemo(() => {
    if (!user?.isManaged) {
      return sideMenuItemsConfig;
    }

    return sideMenuItemsConfig.filter((item) => {
      return item.to !== '/add-project';
    });
  }, [user?.isManaged]);

  const sideMenuItemsConfigWithTour = useMemo(() => {
    return filteredSideMenuItems.map((item) => {
      let ref;
      switch (item.to) {
        case '/dashboard':
          ref = tourRefs.DashboardRef;
          break;
        case '/projects':
          ref = tourRefs.ProjectsRef;
          break;
        case '/add-project':
          ref = tourRefs.AddNewProjectRef;
          break;
        case '/data-resources':
          ref = tourRefs.DataResourcesRef;
          break;
        case '/settings':
          ref = tourRefs.SettingsRef;
          break;
        default:
          break;
      }
      if (!ref) return item;
      return { ...item, label: <div ref={ref}>{item.label}</div> };
    });
  }, [
    filteredSideMenuItems,
    tourRefs.AddNewProjectRef,
    tourRefs.DashboardRef,
    tourRefs.DataResourcesRef,
    tourRefs.ProjectsRef,
    tourRefs.SettingsRef,
  ]);

  const sideMenuItems = useMemo(() => {
    if (user?.isAdmin) {
      return adminPanelsSideMenuItemsConfig;
    }

    if (user?.isOnBoardingTourFinished === false) {
      return sideMenuItemsConfigWithTour;
    }

    return filteredSideMenuItems;
  }, [user?.isAdmin, user?.isOnBoardingTourFinished, filteredSideMenuItems, sideMenuItemsConfigWithTour]);

  const selectedKeys = useMemo(() => {
    let selectedItem = sideMenuItems.find((menuItem) => pathname === menuItem.to);

    if (!selectedItem) {
      selectedItem = sideMenuItems.find((menuItem) => pathname.startsWith(menuItem.to));
    }

    if (selectedItem) {
      return [selectedItem.key];
    }

    return undefined;
  }, [pathname, sideMenuItems]);

  const handleClick = ({ key }: { key: string }) => {
    const item = sideMenuItems.find((menuItem) => menuItem.key === key);

    if (item?.to && pathname !== item.to) {
      navigate(item?.to);
    }
  };

  return (
    <StyledSideMenu
      mode="vertical"
      items={sideMenuItems}
      onClick={handleClick}
      selectedKeys={selectedKeys}
      {...props}
    />
  );
};
