import type { FC } from 'react';
import type { InputProps as AntDInputProps } from 'antd';
import type { PasswordProps } from 'antd/es/input';
import { StyledInput, StyledPasswordInput } from './Input.styles';
import { ReactComponent as VisibilityOff } from '../../../assets/icons/visibility-off.svg';
import { ReactComponent as VisibilityOn } from '../../../assets/icons/visibility-on.svg';


interface SimpleInput extends AntDInputProps {
  iconRender?: never;
}
type InputProps = SimpleInput | PasswordProps;

export const Input: FC<InputProps> = ({ type, ...rest }) => {
  if (type === 'password') {
    const iconRender = (visible) => (visible
      ? <VisibilityOff style={{ cursor: 'pointer' }} />
      : <VisibilityOn style={{ cursor: 'pointer' }} />);

    return (
      <StyledPasswordInput
        type={type}
        {...rest}
        iconRender={iconRender}
      />
    );
  }
  return (
    <StyledInput
      type={type}
      {...rest}
    />
  );
};
