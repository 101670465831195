import type { FC } from 'react';
import type { SpinProps } from 'antd';
import { StyledSpinner, StyledSpinnerOverlay } from './Spinner.styles';


interface SpinnerProps extends SpinProps {
  withOverlay?: boolean
}

export const Spinner: FC<SpinnerProps> = ({ withOverlay = false, ...rest }) => (
  <>
    {withOverlay ? (
      <StyledSpinnerOverlay>
        <StyledSpinner {...rest} />
      </StyledSpinnerOverlay>
    ) : (
      <StyledSpinner {...rest} />
    )}
  </>
);
