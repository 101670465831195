import { FC, ReactNode, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';


export const PublicRoute: FC<{ children: ReactNode }> = ({ children }) => {
  const { firebaseUser, user, isUserLoading } = useAuth();

  const shouldNavigateToRoot = useMemo(() => {
    const doesUserExist = !isUserLoading && firebaseUser && user;

    if (user?.isAdmin) {
      return true;
    }

    if (user && !user.companyId) {
      return false;
    }

    return doesUserExist;
  }, [isUserLoading, firebaseUser, user]);

  if (shouldNavigateToRoot) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};
