import {
  FC, useCallback, useEffect, useMemo, useState
} from 'react';
import {
  Link, useLocation, useMatch, useNavigate
} from 'react-router-dom';
import { Dropdown, Space, Tag } from 'antd';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAuth } from '../../../contexts/AuthContext';
import { Button } from '../../atoms/Button';
import { Breadcrumbs } from '../../molecules/Breadcrumbs';
import { timeOfDay } from '../../../helpers/getTimeOfDay';
import { adminPanelsSideMenuItemsConfig, sideMenuItemsConfig } from '../../../constants/sideMenuItemsConfig';
import { breadcrumbNameMap } from '../../../constants/breadcrumbNameMap';
import { ReactComponent as DropDownIcon } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as InviteTeamMember } from '../../../assets/icons/invite.svg';
import { ReactComponent as SettingsIconOutline } from '../../../assets/icons/outlined/settings-outline.svg';
import {
  StyledHeader,
  StyledHeaderTitle,
  StyledProfileCard,
  StyledRightContent,
  StyledUserAvatar,
  StyledUserName,
  StyledUserEmail,
  StyledPageDescription,
  StyledLogoutOutlined,
  StyledWelcomeBlock
} from './Header.styles';
import { getProject } from '../../../api/project';
import { Project } from '../../../types/project';
import { NotificationCenter } from '../../molecules/NotificationCenter';
import { getOneDataResource } from '../../../api/dataResources';
import { DataResource } from '../../../types/dataResources';
import { Spinner } from '../../atoms/Spinner';


export const Header: FC = () => {
  const {
    logout,
    user,
    isUserLoading,
    fetchSubscriptionPlanQuota,
    subscriptionPlanQuota,
    isSubscriptionPlanQuotaLoading
  } = useAuth();
  const { pathname } = useLocation();
  const [project, setProject] = useState<Project | null>(null);
  const [dataResource, setDataResource] = useState<DataResource>();

  const navigate = useNavigate();
  const isEditProjectRouteMatch = useMatch('/projects/:id/edit');
  const isViewDetailsProjectRouteMatch = useMatch('/projects/:id');
  const isViewDetailsDataResourceRouteMatch = useMatch('/data-resources/:id');

  const isDashboardPage = pathname.includes('/dashboard');
  // TODO: Check if this should be /admin/companies
  const isAdminPanel = pathname.includes('/admin');

  useEffect(() => {
    if (user && !isUserLoading && !user.isAdmin) {
      fetchSubscriptionPlanQuota();
    }
  }, [fetchSubscriptionPlanQuota, isUserLoading, user]);

  const userName = useMemo(() => {
    return user?.firstName || user?.name;
  }, [user]);

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      navigate('/login');
    } catch (err) {
      console.log('err', err);
    }
  }, [logout, navigate]);

  const currentPageSideMenuConfig = useMemo(() => {
    if (user?.isAdmin) {
      return adminPanelsSideMenuItemsConfig.find(({ to }) => pathname.includes(to));
    }

    if (isEditProjectRouteMatch) {
      return {
        label: 'Edit survey'
      };
    }

    if (isViewDetailsProjectRouteMatch) {
      return {
        label: 'Survey'
      };
    }

    return sideMenuItemsConfig.find(({ to }) => pathname.includes(to));
  }, [isEditProjectRouteMatch, isViewDetailsProjectRouteMatch, pathname, user?.isAdmin]);

  useEffect(() => {
    const fetchProject = async () => {
      const projectId = isEditProjectRouteMatch?.params?.id || isViewDetailsProjectRouteMatch?.params.id;

      if (projectId) {
        try {
          const projectData = await getProject(projectId);
          setProject(projectData);
        } catch (err) {
          console.log('err', err);
        }
      }
    };


    fetchProject();
  }, [isEditProjectRouteMatch?.params?.id, isViewDetailsProjectRouteMatch?.params.id]);

  useEffect(() => {
    const dataResourceId = isViewDetailsDataResourceRouteMatch?.params.id;

    if (dataResourceId) {
      const fetchDataResource = async () => {
        try {
          const data = await getOneDataResource(dataResourceId);
          setDataResource(data);
        } catch (err) {
          console.log('err', err);
        }
      };

      fetchDataResource();
    }
  }, [isViewDetailsDataResourceRouteMatch?.params.id]);

  const breadcrumbItems = useMemo(() => {
    const pathSnippets = pathname.split('/').filter(Boolean);
    const projectId = isEditProjectRouteMatch?.params?.id
        || isViewDetailsProjectRouteMatch?.params.id
        || isViewDetailsDataResourceRouteMatch?.params.id;

    if (projectId) {
      return pathSnippets.map((_, index, currentArray) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

        if (url.includes(`/data-resources/${projectId}`)) {
          let title = '';

          if (dataResource) {
            const { name: dataResourceName, metadata: dataResourceMetadata } = dataResource;

            title = `${dataResourceName} ${dataResourceMetadata.country} ${dataResourceMetadata.year}`;
          }

          return { key: 'Survey', title };
        }

        if (project && url.endsWith(projectId)) {
          return { key: project.name, title: <Link to={url}>{project.name}</Link> };
        }

        if (index + 1 === currentArray.length) {
          return { key: 'edit', title: 'Edit' };
        }

        return { title: breadcrumbNameMap[url] };
      });
    }

    return pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      return { key: breadcrumbNameMap[url], title: breadcrumbNameMap[url] };
    });
  }, [
    pathname,
    isEditProjectRouteMatch?.params?.id,
    isViewDetailsProjectRouteMatch?.params.id,
    isViewDetailsDataResourceRouteMatch?.params.id,
    project,
    dataResource
  ]);

  const dropDownMenuItems: MenuProps['items'] = [
    {
      key: '1',
      label: <Link to="/settings">Settings</Link>,
      icon: <SettingsIconOutline />
    },
    {
      key: '2',
      label: 'Log out',
      icon: <StyledLogoutOutlined />,
      onClick: handleLogout
    },
  ];

  const renderButton = useMemo(() => {
    if (
      pathname.includes('/data-resources')
      || pathname.includes('/competitors-pricing')
      || pathname.includes('/users')
      || isAdminPanel) {
      return null;
    }

    if (pathname.includes('/settings')) {
      return (
        <Button
          variant="default"
          onClick={() => navigate('/settings/users')}
          icon={<InviteTeamMember />}
        >
          Invite a team member
        </Button>
      );
    }

    // Note: If user's subscription is managed => hide Buy Additional Survey button
    if (user?.isManaged) {
      return;
    }

    const projectsLeft = subscriptionPlanQuota?.availableProjectsLimit ?? 0;
    const buttonText = projectsLeft && projectsLeft > 0 ? 'Create New Survey' : 'Buy Additional Survey';

    return (
      <>
        <span style={{ position: 'relative' }}>
          Surveys left:
          &nbsp;
          {isSubscriptionPlanQuotaLoading ? (
            <Spinner style={{ position: 'static', transform: 'unset' }} />
          ) : projectsLeft }
        </span>
        <Button
          type="primary"
          // TODO: Redirect to settings
          onClick={() => navigate('/add-project')}
          icon={<PlusOutlined />}
        >
          {buttonText}
        </Button>
      </>
    );
  }, [
    pathname,
    isAdminPanel,
    subscriptionPlanQuota?.availableProjectsLimit,
    isSubscriptionPlanQuotaLoading,
    navigate,
    user?.isManaged
  ]);

  const renderHeaderTitle = useMemo(() => {
    if (isDashboardPage || isAdminPanel) {
      return (
        <>
          <StyledWelcomeBlock>
            <span className="welcome-block">
              Good
              {' '}
              {timeOfDay()}
              ,
            </span>
            {' '}
            <b>{userName}</b>
          </StyledWelcomeBlock>
          <StyledPageDescription>Your performance summary this month</StyledPageDescription>
        </>
      );
    }

    if (
      currentPageSideMenuConfig
      && 'to' in currentPageSideMenuConfig
      && currentPageSideMenuConfig.to === '/competitors-pricing'
    ) {
      return (
        <>
          {currentPageSideMenuConfig.label}
          <Tag
            color="success"
            style={{ verticalAlign: 'middle', marginLeft: 4 }}
          >
            Beta
          </Tag>
        </>
      );
    }

    return currentPageSideMenuConfig?.label;
  }, [isDashboardPage, isAdminPanel, currentPageSideMenuConfig, userName]);

  return (
    <>
      <StyledHeader>
        <div>
          <StyledHeaderTitle>{renderHeaderTitle}</StyledHeaderTitle>
          {!isDashboardPage && !isAdminPanel ? (
            <Breadcrumbs breadcrumbItems={breadcrumbItems} />
          ) : null}
        </div>

        <StyledRightContent isDashboardPage={isDashboardPage}>
          {!user?.isAdmin ? renderButton : null}

          <div style={{ display: 'flex', alignItems: 'baseline', flexDirection: 'row' }}>
            <NotificationCenter />

            {user ? (
              <StyledProfileCard>
                <Dropdown
                  menu={{ items: dropDownMenuItems }}
                  trigger={['click']}
                  placement="bottomRight"
                >
                  <Space>
                    <StyledUserAvatar avatarUrl={user.avatar} />
                    <div>
                      <StyledUserName>{userName}</StyledUserName>
                      <StyledUserEmail>{user.email}</StyledUserEmail>
                    </div>
                    <DropDownIcon />
                  </Space>
                </Dropdown>
              </StyledProfileCard>
            ) : (
              <Button onClick={handleLogout} icon={<StyledLogoutOutlined />}>
                Log out
              </Button>
            )}
          </div>

        </StyledRightContent>
      </StyledHeader>
    </>
  );
};

