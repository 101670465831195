import styled from 'styled-components';
import { Alert, Layout } from 'antd';
import { Link } from 'react-router-dom';
import { tabletSize } from '../../../constants/mediaQuery';
import { Button } from '../../atoms/Button';


export const StyledAppLayoutSider = styled(Layout.Sider)`
  z-index: 2;
  padding-top: 40px;
  padding-inline: 16px;
  overflow: auto;
  
  && {
    background: ${({ theme }) => theme.currentModeConfig.contentBackground};
  }

  && > .ant-layout-sider-trigger {
    left: 0;
    background: ${({ theme }) => theme.currentModeConfig.contentBackground};
    
    svg {
      fill: ${({ theme }) => theme.currentModeConfig.titleVariant2};
    }
  }
`;

export const StyledAppLayoutContent = styled(Layout.Content)`
  position: relative;  
  
  && {
    --headerHeight: 114px;

    padding: 24px 20px 60px;

    max-height: calc(100vh - var(--headerHeight));
    min-height: calc(100vh - var(--headerHeight));
    overflow-x: auto;
    background-color: ${({ theme }) => theme.currentModeConfig.appLayoutBackground};

    @media (max-width: ${tabletSize}px) {
      padding: 12px 12px 24px;
    }
  }
`;

export const StyledAppLayoutSiderLogoLink = styled(Link)`
  display: block;
  margin-bottom: 60px;
  text-align: center;
`;

export const StyledEmailVerificationAlert = styled(Alert)`
  border-radius: 0;
  
  .ant-alert-message {
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-align: center;
  }
`;

export const StyledEmailVerificationAlertButton = styled(Button).attrs({ type: 'link' })`
  && {
    display: inline-flex;
    padding: 0;
  }
`;
