import styled from 'styled-components';
import { Result } from 'antd';


export const StyledErrorFallback = styled(Result)`
  && {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    
    & > .ant-result-icon {
      margin: 0;
    }
  }
`;
