import { createGlobalStyle } from 'styled-components';
import { color } from './colors';
import InterTTF from '../assets/fonts/Inter/Inter-VariableFont.ttf';
import { shadow } from './shadows';


export const theme = {
  color,
  shadow,
};


export const darkModeConfig = {
  appLayoutBackground: color.darkMode.appLayoutBackground,
  contentBackground: color.darkMode.contentBackground,
  tableBackground: color.darkMode.appLayoutBackground,
  logoColor: color.greyscale.grey40,
  menuItemColor: color.greyscale.grey40,
  titleVariant1: color.greyscale.grey10,
  titleVariant2: color.greyscale.white,
  titleVariant3: color.greyscale.grey40,
  selectBorderColor: color.greyscale.grey60,
  selectBackgroundColor: 'transparent',
  inputBorderColor: color.greyscale.grey60,
  descriptionColor: color.greyscale.grey20,
  button: {
    backgroundColorPrimaryDisabled: color.greyscale.grey80,
    backgroundColorSecondaryDisabled: color.greyscale.grey60,
  },
  account: {
    labelColor: color.greyscale.grey60,
    inputData: color.greyscale.grey30,
  },
  topBarStatistics: {
    title: color.greyscale.grey40,
    result: color.greyscale.grey20,
  },
  tabs: {
    dashboard: {
      background: color.darkMode.contentBackground,
      color: color.greyscale.grey40,
      border: 'transparent',
      activeTabBackground: color.greyscale.grey80,
      activeTabColor: color.primary.blue80,
      legend: color.greyscale.grey80,
    }
  },
  dateBlock: {
    border: color.greyscale.grey80,
    title: color.greyscale.grey40
  },
  settings: {
    paymentPlanCardBg: color.greyscale.grey30
  },
  collapse: {
    collapseHeaderBackground: color.greyscale.grey80,
    collapseHeaderColor: color.greyscale.grey10,
    collapseBodyBackground: color.greyscale.grey60,
    collapseCheckboxBackground: 'transparent',
    collapseCheckboxBackgroundChecked: 'transparent',
    collapseCheckedBackground: color.greyscale.grey10,
    collapseCheckboxBorderColor: color.greyscale.grey10,
    collapseCheckboxBorderColorVariant2: color.greyscale.grey30,
  },
  paymentPlanCard: {
    background: color.darkMode.appLayoutBackground,
    preferredMessage: color.greyscale.white,
    content: color.greyscale.white,
    featuresListItem: color.greyscale.white
  }
};

export const lightModeConfig = {
  appLayoutBackground: color.greyscale.grey10,
  contentBackground: color.greyscale.white,
  tableBackground: color.greyscale.white,
  logoColor: color.greyscale.grey,
  menuItemColor: color.greyscale.grey60,
  titleVariant1: color.greyscale.grey80,
  titleVariant2: color.greyscale.grey,
  titleVariant3: color.greyscale.grey,
  selectBorderColor: 'transparent',
  selectBackgroundColor: color.greyscale.grey10,
  inputBorderColor: color.greyscale.grey30,
  descriptionColor: color.greyscale.grey60,
  button: {
    backgroundColorPrimaryDisabled: color.greyscale.grey30,
    backgroundColorSecondaryDisabled: color.greyscale.white,
  },
  account: {
    labelColor: color.greyscale.grey,
    inputData: color.greyscale.grey60,
  },
  topBarStatistics: {
    title: color.greyscale.grey60,
    result: color.greyscale.grey,
  },
  tabs: {
    dashboard: {
      background: color.greyscale.grey10,
      color: color.greyscale.grey60,
      border: color.greyscale.grey10,
      activeTabBackground: color.primary.blue20,
      activeTabColor: color.primary.blue,
      legend: color.greyscale.grey5,
    }
  },
  dateBlock: {
    border: color.greyscale.grey30,
    title: color.greyscale.grey60
  },
  settings: {
    paymentPlanCardBg: color.greyscale.white
  },
  collapse: {
    collapseHeaderBackground: color.greyscale.grey20,
    collapseHeaderColor: color.greyscale.grey60,
    collapseBodyBackground: color.greyscale.grey5,
    collapseCheckedBackground: 'transparent',
    collapseCheckboxBackground: color.greyscale.white,
    collapseCheckboxBackgroundChecked: color.primary.blue,
    collapseCheckboxBorderColor: color.greyscale.grey30,
    collapseCheckboxBorderColorVariant2: color.greyscale.grey40,
  },
  paymentPlanCard: {
    background: color.greyscale.white,
    preferredMessage: color.primary.blue,
    content: color.greyscale.grey,
    featuresListItem: color.tertiary.blue
  }
};

export type CurrentModeConfigType = typeof lightModeConfig | typeof darkModeConfig;

export type ThemeType = typeof theme;

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: Inter;
    src: url(${InterTTF}) format(truetype);
    font-style: normal;
  }
`;

export const antDesignTheme = {
  token: {
    colorPrimary: color.primary.blue,
    colorPrimaryDisabled: color.greyscale.grey30,
    colorPrimaryHover: color.primary.blue80,
    colorPrimaryBorderHover: color.primary.blue60,
  },
  components: {
    Form: {
      fontFamily: 'Inter',
    },
    Input: {
      fontFamily: 'Inter',
      borderRadius: 4,
      backgroundColor: color.greyscale.white,
      colorBorder: color.greyscale.grey30,
      colorPrimaryHover: color.greyscale.grey10,
      colorError: color.states.red,
    },
    InputNumber: {
      fontFamily: 'Inter',
      borderRadius: 4,
      backgroundColor: color.greyscale.white,
      colorBorder: color.greyscale.grey30,
      colorPrimaryHover: color.greyscale.grey10,
      colorError: color.states.red,
    },
    Select: {
      fontFamily: 'Inter',
      borderRadius: 4,
      backgroundColor: color.greyscale.white,
      colorBorder: color.greyscale.grey30,
      colorPrimaryHover: color.greyscale.grey10,
    },
    Typography: {
      colorText: color.greyscale.grey,
      fontFamily: 'Inter',
    },
    Button: {
      colorText: color.primary.blue,
    }
  },
};

export const antDesignDarkTheme = {
  token: {
    colorPrimary: color.primary.blue,
    colorPrimaryDisabled: color.greyscale.grey30,
    colorPrimaryHover: color.primary.blue80,
    colorPrimaryBorderHover: color.primary.blue60,
  },
  components: {
    Form: {
      fontFamily: 'Inter',
    },
    Input: {
      fontFamily: 'Inter',
      borderRadius: 4,
      backgroundColor: color.greyscale.grey80,
      colorBorder: color.greyscale.grey60,
      colorPrimaryHover: color.greyscale.grey40,
      colorError: color.states.red,
    },
    InputNumber: {
      fontFamily: 'Inter',
      borderRadius: 4,
      backgroundColor: color.greyscale.grey80,
      colorBorder: color.greyscale.grey60,
      colorPrimaryHover: color.greyscale.grey40,
      colorError: color.states.red,
    },
    Select: {
      fontFamily: 'Inter',
      borderRadius: 4,
      backgroundColor: color.greyscale.grey80,
      colorBorder: color.greyscale.grey60,
      colorPrimaryHover: color.greyscale.grey40,
    },
    Typography: {
      colorText: color.greyscale.white,
      fontFamily: 'Inter',
    },
    Button: {
      colorText: color.primary.blue
    },
  },
};
