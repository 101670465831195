import {
  FC, ReactNode, useEffect
} from 'react';
import { useAuth } from '../../contexts/AuthContext';


export const AnonymousRoute: FC<{ children: ReactNode }> = ({ children }) => {
  const {
    firebaseUser, user, isUserLoading, signInAnonymousUser
  } = useAuth();

  useEffect(() => {
    const shouldLogInAnonymousUser = !firebaseUser && !isUserLoading;
    if (shouldLogInAnonymousUser) {
      signInAnonymousUser().catch(console.log);
    }
  }, [firebaseUser, isUserLoading, signInAnonymousUser, user]);

  return <>{children}</>;
};
