import styled from 'styled-components';
import { ReactComponent as LogoSvg } from '../../../assets/LogoComplete.svg';


export const StyledLogo = styled(LogoSvg)`
  pointer-events: none;
  user-select: none;

  & > path:first-child {
    fill: ${({ theme }) => theme.currentModeConfig.logoColor};
  }
`;
