import styled from 'styled-components';
import { Menu } from 'antd';


export const StyledSideMenu = styled(Menu)`
  &&& {
    border: none;
    background: transparent;
  }

  &&:not(.ant-menu-inline-collapsed) > .ant-menu-item {
    width: 100%;
    margin-inline: 0;
  }

  && > .ant-menu-item {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.currentModeConfig.menuItemColor};
    padding: 8px 6px;
    transition: margin 0.3s, border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    svg {
      min-width: 24px;
    }
  }

  && > .ant-menu-item-selected {
    color: ${({ theme }) => theme.color.primary.blue};
    font-weight: 700;
  }
`;

