import { FC, PropsWithChildren, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Form } from 'antd';
import { FormItem } from '../molecules/FormItem';
import { AuthLayout } from '../templates/AuthLayout';
import { Input } from '../atoms/Input';
import { Button } from '../atoms/Button';
import { useAlert } from '../../contexts/AlertContext';
import { validatePartnerPassword } from '../../api/user';


interface PartnerSuggestionFormValue {
  email: string;
  company: string;
  password: string;
}

export const PartnerRoute: FC<PropsWithChildren> = ({ children }) => {
  const alert = useAlert();
  const [, setSearchParams] = useSearchParams();
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [isValidatePasswordLoading, setIsValidatePasswordLoading] = useState(false);

  const onFormSubmit = async ({ email, company, password }: PartnerSuggestionFormValue) => {
    setIsValidatePasswordLoading(true);

    try {
      await validatePartnerPassword(password);

      setSearchParams({ email, company });
      setIsPasswordCorrect(true);
    } catch (err) {
      console.log('err', err);
      alert?.displayErrorAlert(err);
    } finally {
      setIsValidatePasswordLoading(false);
    }
  };

  if (!isPasswordCorrect) {
    return (
      <AuthLayout>
        <Form
          requiredMark={false}
          layout="vertical"
          onFinish={onFormSubmit}
          disabled={isValidatePasswordLoading}
        >
          <FormItem label="Email" name="email">
            <Input placeholder="Enter email" type="email" autoComplete="off" />
          </FormItem>
          <FormItem label="Company" name="company">
            <Input placeholder="Enter company name" type="text" />
          </FormItem>
          <FormItem label="Password" name="password">
            <Input placeholder="Enter password" type="password" />
          </FormItem>

          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 'auto' }}
            loading={isValidatePasswordLoading}
          >
            Submit
          </Button>
        </Form>
      </AuthLayout>
    );
  }

  return <>{children}</>;
};
