import { DefaultTheme } from 'styled-components';
import { ButtonProps, ButtonVariantsType } from './Button.types';


export const getFontSize = (size: ButtonProps['size']) => {
  const sizesMap = {
    small: '12px',
    middle: '14px',
    large: '16px',
  };
  switch (size) {
    case 'small':
      return sizesMap.small;
    case 'middle':
      return sizesMap.middle;
    case 'large':
      return sizesMap.large;
    default:
      return sizesMap.middle;
  }
};

export const getHeight = (size: ButtonProps['size']) => {
  const sizesMap = {
    small: '36px',
    middle: '40px',
    large: '48px',
  };
  switch (size) {
    case 'small':
      return sizesMap.small;
    case 'middle':
      return sizesMap.middle;
    case 'large':
      return sizesMap.large;
    default:
      return sizesMap.middle;
  }
};

export const getPadding = (size: ButtonProps['size']) => {
  const sizesMap = {
    small: '8px 20px',
    middle: '10px 20px',
    large: '14px 22px',
  };
  switch (size) {
    case 'small':
      return sizesMap.small;
    case 'middle':
      return sizesMap.middle;
    case 'large':
      return sizesMap.large;
    default:
      return sizesMap.middle;
  }
};

export const getTextColor = (
  theme: DefaultTheme,
  variant: ButtonVariantsType,
  isDisabled?: boolean
) => {
  switch (variant) {
    case 'primary':
      return theme.color.greyscale.white;
    case 'default':
    case 'secondary':
      return isDisabled ? theme.color.greyscale.grey30 : theme.color.primary.blue;
    case 'tertiary':
      return isDisabled ? theme.color.greyscale.grey30 : theme.color.greyscale.grey60;
    case 'link':
      return isDisabled ? theme.color.greyscale.grey30 : theme.color.primary.blue;
    default:
      return theme.color.primary.blue;
  }
};

export const getTextColorHover = (
  theme: DefaultTheme,
  variant: ButtonVariantsType,
  isDisabled?: boolean
) => {
  switch (variant) {
    case 'primary':
      return theme.color.greyscale.white;
    case 'secondary':
    case 'default':
      return isDisabled ? theme.color.greyscale.grey30 : theme.color.primary.blue80;
    case 'tertiary':
      return isDisabled ? theme.color.greyscale.grey30 : theme.color.greyscale.grey40;
    case 'link':
      return isDisabled ? theme.color.greyscale.grey30 : theme.color.primary.blue80;
    default:
      return theme.color.primary.blue80;
  }
};

export const getBackgroundColor = (theme: DefaultTheme, variant: ButtonVariantsType) => {
  switch (variant) {
    case 'primary':
      return theme.color.primary.blue;
    case 'secondary':
    case 'tertiary':
    case 'link':
    default:
      return 'none';
  }
};

export const getBackgroundColorDisabled = (theme: DefaultTheme, variant: ButtonVariantsType) => {
  switch (variant) {
    case 'primary':
      return theme.currentModeConfig.button.backgroundColorPrimaryDisabled;
    case 'secondary':
    case 'tertiary':
      return theme.currentModeConfig.button.backgroundColorSecondaryDisabled;
    case 'link':
      return 'none';
    default:
      return theme.color.primary.blue80;
  }
};

export const getBorder = (theme: DefaultTheme, variant: ButtonVariantsType, isDisabled?: boolean) => {
  const border = '1px solid';

  switch (variant) {
    case 'primary':
    case 'link':
      return 'none';
    case 'secondary':
    case 'default':
      return isDisabled ? `${border} ${theme.color.greyscale.grey30}` : `${border} ${theme.color.primary.blue}`;
    case 'tertiary':
      return isDisabled ? `${border} ${theme.color.greyscale.grey30}` : `${border} ${theme.color.greyscale.grey60}`;
    default:
      return `1px solid ${theme.color.primary.blue}`;
  }
};

export const getBorderHover = (
  theme: DefaultTheme,
  variant: ButtonVariantsType,
  isDisabled?: boolean
) => {
  const border = '1px solid';

  switch (variant) {
    case 'primary':
    case 'link':
      return 'none';
    case 'secondary':
    case 'default':
      return isDisabled ? `${border} ${theme.color.greyscale.grey30}` : `${border} ${theme.color.primary.blue}`;
    case 'tertiary':
      return isDisabled ? `${border} ${theme.color.greyscale.grey30}` : `${border} ${theme.color.greyscale.grey40}`;
    default:
      return `${border} ${theme.color.primary.blue80}`;
  }
};
