import axios, { AxiosRequestConfig } from 'axios';
import { DataResources, DataResource, AdminDataResource } from '../types/dataResources';
import { ProjectStatisticsMetricsType, SurveyPricesAnalytics } from '../types/analytics';
import { OptionType } from '../types/option.type';


export const getAllDataResources = async (): Promise<DataResources[]> => {
  const url = '/data-resources';

  return axios.get(url);
};

export const getOneDataResource = async (id: string): Promise<DataResource> => {
  const url = `/data-resources/${id}`;

  return axios.get(url);
};

export const getEntireMarketAnalytics = async (dataResourceId: string, params?: AxiosRequestConfig['params'])
: Promise<SurveyPricesAnalytics> => {
  const url = `/analytics/data-resource/${dataResourceId}/prices`;

  return axios.get(url, { params });
};

export const getSegmentationAnalytics = async (dataResourceId: string, params?: AxiosRequestConfig['params']) => {
  const url = `/analytics/data-resource/${dataResourceId}/prices/question-answers`;

  return axios.get(url, { params });
};

export const getDataResourceFiltersData = async (dataResourceId: string) => {
  const url = `/analytics/data-resource/${dataResourceId}/prices/filters`;

  return axios.get(url);
};

export const getDataResourceRespondentsStatistics = async (dataResourceId: string)
: Promise<{ total: OptionType[]; genders: OptionType[] }> => {
  const url = `/analytics/data-resource/${dataResourceId}/respondents/statistics`;

  return axios.get(url);
};

export const getDataResourceStatisticsMetrics = async (dataResourceId: string)
: Promise<ProjectStatisticsMetricsType> => {
  const url = `/analytics/data-resource/${dataResourceId}/metrics`;

  return axios.get(url);
};

export const getNewestDataResource = async (): Promise<DataResource> => {
  const url = '/data-resources/newest';

  return axios.get(url);
};

export const getAdminDataResources = async (): Promise<AdminDataResource[]> => {
  const url = '/admin/data-resources';

  return axios.get(url);
};

export const updateAdminDataResource = async (dataResourceId: string, dataResource: AdminDataResource) => {
  const url = `/admin/data-resources/${dataResourceId}`;

  return axios.patch(url, dataResource);
};

export const deleteAdminDataResource = async (dataResourceId: string): Promise<void> => {
  const url = `/admin/data-resources/${dataResourceId}`;

  return axios.delete(url);
};

export const getAdminDataResourcesCategories = async (): Promise<string[]> => {
  const url = '/admin/data-resources/categories';

  return axios.get(url);
};

export const createDataResource = async (dataResource: AdminDataResource): Promise<AdminDataResource> => {
  const url = '/admin/data-resources/create';

  return axios.post(url, dataResource);
};


