import axios from 'axios';
import Cookies from 'js-cookie';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';


export const setupAxios = () => {
  setBaseUrl();
  setInterceptors();
};

function setBaseUrl() {
  axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
}

function setInterceptors() {
  axios.interceptors.response.use(
    (response) => {
      const { data } = response;

      if (data && 'errorMessage' in data && data.errorMessage === 'Timeout') {
        throw new Error('Something went wrong, please try again later');
      }

      return data;
    },
    async (error) => {
      if (axios.isCancel(error)) {
        return Promise.reject(new axios.Cancel(error.message));
      }

      const disabledUserErrorMessage = 'User is disabled';
      if (error.response?.data && 'error' in error.response.data
          && error.response.data.error === disabledUserErrorMessage) {
        Cookies.remove('auth-token');
        await signOut(auth);

        return Promise.reject(disabledUserErrorMessage);
      }

      // Refresh access token
      const originalRequest = error.config;

      if (error.response?.status === 403 && !originalRequest._retry && auth.currentUser) {
        originalRequest._retry = true;

        const token = await auth.currentUser.getIdToken();

        Cookies.set('auth-token', token);

        axios.defaults.headers.common.Authorization = `Bearer ${token}`;

        return axios(originalRequest);
      }

      if (error.response && 'data' in error.response) {
        const { data } = error.response;
        return Promise.reject(data);
      }
      return Promise.reject(error);
    },
  );

  axios.interceptors.request.use(
    async (config) => {
      let token = Cookies.get('auth-token');

      if (!token && auth.currentUser) {
        token = await auth.currentUser.getIdToken(true);
        Cookies.set('auth-token', token);
      }

      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    // TODO: Clear cookies
    (error) => Promise.reject(error),
  );
}
