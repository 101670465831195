import {
  createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState
} from 'react';


const themeModes = {
  DARK: 'dark',
  LIGHT: 'light'
};

interface DarkModeValue {
  isDarkMode: boolean;
  toggleDarkMode: (value: boolean) => void;
}

const DarkModeContext = createContext({} as DarkModeValue);

export const useDarkMode = () => useContext(DarkModeContext);

export const DarkModeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isDarkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    const currentMode = localStorage.getItem('theme');
    setDarkMode(currentMode === themeModes.DARK);
  }, []);

  const toggleDarkMode = useCallback((isDark: boolean) => {
    const themeMode = isDark ? themeModes.DARK : themeModes.LIGHT;
    localStorage.setItem('theme', themeMode);
    setDarkMode(isDark);
  }, []);

  const value = useMemo(() => {
    return { isDarkMode, toggleDarkMode };
  }, [isDarkMode, toggleDarkMode]);

  return (
    <DarkModeContext.Provider value={value}>
      {children}
    </DarkModeContext.Provider>
  );
};

