import axios from 'axios';


export const sendPasswordResetEmail = async (email: string, redirectTo: string): Promise<void> => {
  const url = '/email-sender/forgot-password';

  return axios.post(url, { email, redirectTo });
};

export const sendInviteEmail = async ({
  name,
  email,
  redirectTo
} : { name: string; email: string; redirectTo: string }): Promise<void> => {
  const url = '/email-sender/invite';

  return axios.post(url, { name, email, redirectTo });
};

export const sendEmailVerificationEmail = async (redirectTo: string): Promise<void> => {
  const url = '/email-sender/email-verification';

  return axios.post(url, { redirectTo });
};
