import {
  createContext, createRef, FC, ReactNode, RefObject, useContext, useMemo
} from 'react';


const EntireMarketRef = createRef<HTMLDivElement>();
const SegmentationRef = createRef<HTMLDivElement>();
const DashboardRef = createRef<HTMLDivElement>();
const HelpResourceRef = createRef<HTMLDivElement>();
const ProjectsRef = createRef<HTMLDivElement>();
const AddNewProjectRef = createRef<HTMLDivElement>();
const DataResourcesRef = createRef<HTMLDivElement>();
const SettingsRef = createRef<HTMLDivElement>();
interface TourContextType {
  EntireMarketRef:RefObject<HTMLDivElement>
  SegmentationRef:RefObject<HTMLDivElement>
  DashboardRef:RefObject<HTMLDivElement>
  ProjectsRef:RefObject<HTMLDivElement>
  AddNewProjectRef:RefObject<HTMLDivElement>
  DataResourcesRef:RefObject<HTMLDivElement>
  HelpResourceRef:RefObject<HTMLDivElement>
  SettingsRef:RefObject<HTMLDivElement>
}


const TourContext = createContext<TourContextType>({
  EntireMarketRef,
  SegmentationRef,
  DashboardRef,
  HelpResourceRef,
  ProjectsRef,
  AddNewProjectRef,
  DataResourcesRef,
  SettingsRef
});

export const useTour = () => useContext(TourContext);


export const TourProvider:FC<{ children: ReactNode }> = ({ children }) => {
  const value = useMemo(() => {
    return {
      EntireMarketRef,
      SegmentationRef,
      DashboardRef,
      HelpResourceRef,
      ProjectsRef,
      AddNewProjectRef,
      DataResourcesRef,
      SettingsRef
    };
  }, []);

  return (
    <TourContext.Provider value={value}>
      {children}
    </TourContext.Provider>
  );
};
